import React, { useEffect, useRef, useState } from 'react';
import MCLog from '../utils/log/MCLog';

// 视频样式常量
// objectFit: 'cover' - 视频内容将被缩放以填充元素的内容框,保持其宽高比
// height - 当窗口宽度大于等于1000px时固定高度为480px,否则自动调整
// width - 宽度设置为100%以填充父容器
// maxWidth - 最大宽度限制为640px以避免在大屏幕上过度拉伸
const VIDEO_STYLE = {
  objectFit: 'cover',
  height: window.innerWidth >= 1000 ? '480px' : 'auto', 
  width: '100%',
  maxWidth: '640px'
};

const BabyHeadShape = () => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [cameraError, setCameraError] = useState(null);
  const [stream, setStream] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [capturedImages, setCapturedImages] = useState([]);
  const [cameraFacingMode, setCameraFacingMode] = useState('environment');

  const startCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: {
          facingMode: cameraFacingMode
        }
      };
      navigator.mediaDevices.getUserMedia(constraints)
        .then(mediaStream => {
          window.stream = mediaStream;
          setStream(mediaStream);
          const video = videoRef.current;
          if (video) {
            video.srcObject = mediaStream;
            video.style.objectFit = 'cover';
            video.style.transform = cameraFacingMode === 'user' ? 'scaleX(-1)' : 'none';
            setCameraError(null);

            // 监听视频加载完成事件以获取实际尺寸
            video.addEventListener('loadedmetadata', () => {
              MCLog.asyncSaveLog(`视频宽度: ${video.videoWidth}`);
              MCLog.asyncSaveLog(`视频高度: ${video.videoHeight}`);
              MCLog.asyncSaveLog(`宽高比: ${video.videoWidth / video.videoHeight}`);
            });
          }
        })
        .catch(error => {
          console.error('getUserMedia error:', error);
          setCameraError('摄像头访问失败，请检查权限设置');
        });
    } else {
      setCameraError('浏览器不支持访问摄像头: 您的浏览器不支持 navigator.mediaDevices.getUserMedia API');
    }
  };

  const switchCamera = () => {
    const newFacing = cameraFacingMode === 'environment' ? 'user' : 'environment';
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
    const constraints = {
      video: {
        facingMode: newFacing
      }
    };
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then(mediaStream => {
          window.stream = mediaStream;
          setStream(mediaStream);
          setCameraFacingMode(newFacing);
          const video = videoRef.current;
          if (video) {
            video.srcObject = mediaStream;
            video.style.objectFit = 'cover';
            video.style.transform = newFacing === 'user' ? 'scaleX(-1)' : 'none';
            setCameraError(null);
          }
        })
        .catch(error => {
          console.error('切换摄像头失败:', error);
          setCameraError('切换摄像头失败，请检查权限设置');
        });
    } else {
      setCameraError('浏览器不支持访问摄像头: 您的浏览器不支持 navigator.mediaDevices.getUserMedia API');
    }
  };

  const takeScreenshot = () => {
    try {
      const video = videoRef.current;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');

      // 计算居中位置
      let sx = 0, sy = 0, sWidth = video.videoWidth, sHeight = video.videoHeight;
      let dx = 0, dy = 0, dWidth = canvas.width, dHeight = canvas.height;

      // 如果是前置摄像头,需要水平翻转
      if (cameraFacingMode === 'user') {
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
      }

      // 绘制视频帧
      ctx.drawImage(video, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

      // 如果是前置摄像头,恢复变换
      if (cameraFacingMode === 'user') {
        ctx.setTransform(1, 0, 0, 1, 0, 0);
      }

      // 如果有叠加图片,绘制叠加图片
      if (selectedImage) {
        const img = new Image();
        img.onload = () => {
          // 计算叠加图片的居中位置
          const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
          const imgWidth = img.width * scale;
          const imgHeight = img.height * scale;
          const imgX = (canvas.width - imgWidth) / 2;
          const imgY = (canvas.height - imgHeight) / 2;

          ctx.globalAlpha = 0.5;
          ctx.drawImage(img, imgX, imgY, imgWidth, imgHeight);
          ctx.globalAlpha = 1.0;
          
          const image = canvas.toDataURL('image/png');
          setCapturedImages(prev => [image, ...prev]);
        };
        img.src = selectedImage;
      } else {
        const image = canvas.toDataURL('image/png');
        setCapturedImages(prev => [image, ...prev]);
      }
    } catch (error) {
      console.error('截图失败:', error);
    }
  };

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const saveAllImages = async () => {
    let JSZipModule;
    try {
      const module = await import('jszip');
      JSZipModule = module.default || module;
    } catch (error) {
      console.error('JSZip加载失败:', error);
      return;
    }

    try {
      const zip = new JSZipModule();
      const timestamp = new Date().getTime();

      for (let i = 0; i < capturedImages.length; i++) {
        const imageData = capturedImages[i].split(',')[1];
        zip.file(`宝宝头型-${i + 1}-${timestamp}.png`, imageData, { base64: true });
      }

      const content = await zip.generateAsync({ type: "blob" });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = `宝宝头型照片-${timestamp}.zip`;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('保存图片失败:', error);
    }
  };

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);

  return (
    <div className="container mx-auto p-4" ref={containerRef}>
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">宝宝头型测量</h2>
          <div className="flex flex-col items-center gap-4">
            <div className="flex gap-4">
              <button 
                className="btn btn-primary"
                onClick={startCamera}
              >
                开启摄像头
              </button>
              <button 
                className="btn btn-primary"
                onClick={switchCamera}
              >
                切换前后摄像头
              </button>
              <button 
                className="btn btn-primary"
                onClick={() => setSelectedImage('/babyHeadShape/head-top.JPG')}
              >
                头顶
              </button>
              <button
                className="btn btn-primary" 
                onClick={() => setSelectedImage('babyHeadShape/head-left.png')}
              >
                朝左
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setSelectedImage('/babyHeadShape/head-right.JPG')}
              >
                朝右
              </button>
            </div>
            
            {cameraError && (
              <div className="alert alert-error">
                {cameraError}
              </div>
            )}

            <div id="preview_all_div" style={{ position: 'relative' }}>
              <video 
                id="preview_video"
                ref={videoRef}
                autoPlay 
                playsInline
                className="border border-gray-300 rounded-lg"
                style={VIDEO_STYLE}
              />

              {selectedImage && (
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="border border-gray-300 rounded-lg"
                    style={{
                      ...VIDEO_STYLE,
                      opacity: 0.5,
                      objectFit: 'contain',
                      maxWidth: '100%',
                      maxHeight: '100%'
                    }}
                  />
                </div>
              )}
            </div>

            <div className="flex gap-4">
              <button 
                className="btn btn-secondary"
                onClick={takeScreenshot}
              >
                截取屏幕
              </button>
              <label className="btn btn-secondary">
                从相册选择
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageSelect}
                  style={{ display: 'none' }}
                />
              </label>
            </div>

            {/* 底部图片展示区域 */}
            {capturedImages.length > 0 && (
              <div className="mt-8 w-full">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-bold">
                    已截取的图片 <span className="text-gray-500">({capturedImages.length}张)</span>
                  </h3>
                  <button
                    onClick={saveAllImages}
                    className="btn btn-primary"
                  >
                    保存全部图片
                  </button>
                </div>
                <div className="relative w-full overflow-x-auto">
                  <div className="flex space-x-4 p-2 scroll-smooth">
                    {capturedImages.map((img, index) => (
                      <div key={index} className="flex-none flex flex-col items-center">
                        <img 
                          src={img} 
                          alt={`截图 ${index + 1}`}
                          className="h-24 w-auto object-cover rounded-lg border border-gray-300 hover:opacity-80 transition-opacity"
                        />
                        <button
                          onClick={() => {
                            const newImages = [...capturedImages];
                            newImages.splice(index, 1);
                            setCapturedImages(newImages);
                          }}
                          className="mt-2 px-2 py-1 text-sm text-red-600 hover:text-red-800 transition-colors"
                        >
                          删除
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BabyHeadShape;
