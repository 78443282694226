// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FlashWord from './FlashWord/FlashWord';
import AzureTTS from './AzureTTS/AzureTTS';
import TTSPage from './EnglishTTS/tts/TTSPage';
import TTSConfigPage from './EnglishTTS/config/TTSConfigPage';
import GoogDriveListSave from './GoogleDrive/GoogDriveListSave';
import DriveFileViewerGoogleDrive from './R2/DriveFileViewerGoogleDrive';
import TimeZoneAlign from './TimeZoneAlign/TimeZoneAlign';
import AudioProcessor from './AudioProcessor/AudioProcessor';
import AudioRegionPlayerSample from './englishAudioRP/AudioRegionPlayerSample';
import EnglishJM from './EnglishJM/EnglishJM';
import EchartDependency from './Dependency/EchartDependency';
import DependencyVisjsGraph from './Dependency/DependencyVisjsGraph';
import EnglishArticleTextProcessorView from './Article/EnglishArticleTextProcessorView';
import WordListCheck from './WordListCheck/WordListCheck';
import YouglishWidget from './fun/YouglishWidget';
import JsonFormat from './json_format/JsonFormat';
import SpeechToText from './EnglishTTS/stt/SpeechToText';
import IOSShortCut from './IosShortCut/IOSShortCut';
import BabyRecord from './baby/BabyRecord';
import BabyHeadShape from './baby/BabyHeadShape';
import MCLogView from './utils/log/MCLogView';

import Home from './Home';

import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/flash-word" element={<FlashWord />} />
          <Route path="/Azure-TTS" element={<AzureTTS />} />
          <Route path="/TTS-Config-Page" element={<TTSConfigPage />} />
          <Route path="/TTS-Page" element={<TTSPage />} />
          <Route path="/GoogDriveListSave" element={<GoogDriveListSave />} />
          <Route path="/TimeZoneAlign" element={<TimeZoneAlign />} />
          <Route path="/AudioProcessor" element={<AudioProcessor />} />
          <Route path="/DriveFileViewerGoogleDrive" element={<DriveFileViewerGoogleDrive />} />
          <Route path="/AudioRegionPlayerSample" element={<AudioRegionPlayerSample />} />
          <Route path="/EnglishJM" element={<EnglishJM />} />
          <Route path="/EchartDependency" element={<EchartDependency />} />
          <Route path="/DependencyVisjsGraph" element={<DependencyVisjsGraph />} />
          <Route path="/EnglishArticleTextProcessorView" element={<EnglishArticleTextProcessorView />} />
          <Route path="/WordListCheck" element={<WordListCheck />} />
          <Route path="/YouglishWidget" element={<YouglishWidget />} />
          <Route path="/JsonFormat" element={<JsonFormat />} />
          <Route path="/SpeechToText" element={<SpeechToText />} />
          <Route path="/IOSShortCut" element={<IOSShortCut />} />
          <Route path="/BabyRecord" element={<BabyRecord />} />'
          <Route path="/BabyHeadShape" element={<BabyHeadShape />} />
          <Route path="/MCLogView" element={<MCLogView />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
