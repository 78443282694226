class MCLog {
  constructor() {
    this.logQueue = [];
    this.isProcessing = false;
    this.logs = []; // 存储日志
  }

  // 添加时间戳并格式化日志
  formatLog(log) {
    const now = new Date();
    const timestamp = now.toISOString();
    return `[${timestamp}] ${log}\n`;
  }

  // 将日志写入本地存储
  async writeToFile(log) {
    try {
      // 获取今天的日期作为key
      const today = new Date().toISOString().split('T')[0];
      const key = `log-${today}`;
      
      // 获取已有日志
      const existingLogs = localStorage.getItem(key) || '';
      
      // 追加新日志
      const newLogs = existingLogs + log;
      
      // 保存到localStorage
      localStorage.setItem(key, newLogs);
      
      // 同时保存到内存中
      this.logs.push(log);
      
      // 如果日志太多,清理旧的
      if(this.logs.length > 1000) {
        this.logs = this.logs.slice(-1000);
      }
      
    } catch (error) {
      console.error('保存日志失败:', error);
    }
  }

  // 处理队列中的日志
  async processQueue() {
    if (this.isProcessing || this.logQueue.length === 0) {
      return;
    }

    this.isProcessing = true;
    
    try {
      while (this.logQueue.length > 0) {
        const log = this.logQueue.shift();
        await this.writeToFile(log);
      }
    } finally {
      this.isProcessing = false;
    }
  }

  // 公开的异步保存日志方法
  async asyncSaveLog(log) {
    const formattedLog = this.formatLog(log);
    this.logQueue.push(formattedLog);
    await this.processQueue();
  }

  // 从本地存储读取日志
  readFromLocal() {
    const today = new Date().toISOString().split('T')[0];
    const key = `log-${today}`;
    const logs = localStorage.getItem(key) || '';
    return logs.split('\n').filter(log => log.length > 0);
  }
}

export default new MCLog();
