import React, { useEffect, useState } from 'react';
import MCLog from './MCLog';

const MCLogView = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    // 每秒更新一次日志
    const timer = setInterval(() => {
      const currentLogs = MCLog.readFromLocal();
      console.log('当前日志:', currentLogs);
      setLogs([...currentLogs]);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="log-container">
      <div className="log-content" style={{
        height: '400px',
        overflowY: 'auto',
        padding: '10px',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        fontFamily: 'monospace',
        whiteSpace: 'pre-wrap'
      }}>
        {logs.map((log, index) => (
          <div key={index} className="log-line">
            {log}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MCLogView;
